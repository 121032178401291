/**
 * Jayride Common js
 * https://jayride.atlassian.net/wiki/spaces/TD/pages/2616819804
 * a script to be used in non-framework web apps
 */

// window.jayride = window.jayride || {};
window.jayride = {
    /**
     * a function that can be used to trigger custom GA events (JAY-1855)
     * @param {string} category AnalyticsEventCategory
     * @param {string} action AnalyticsEventAction
     * @param {string=} label
     * @param {number=} value
     * @param {boolean=} nonInteractionalEvt
     */
    trackEvent: function (category, action, label, value, nonInteractionalEvt) {
        if (!category || !action) {
            throw new Error(`GOOGLE_ANALYTICS_ERROR: event category (${category}) or action (${action}) is not defined`);
            // return;
        }
        if (!window.ga) {
            throw new Error('GOOGLE_ANALYTICS_ERROR: ga is not defined');
            // return;
        }

        const fieldsObject = nonInteractionalEvt ? {
            nonInteraction: true
        } : undefined;

        window.ga('send', 'event', category, action, label, value, fieldsObject);
    },
    searchWidget: {
        /* list of key/value to select Search Widget DOM elements */
        dom: {},
        /* a data model object behind the Search Widget form */
        model: {},
        /* a list of key/value to define state of the Widget */ 
        state: {},
        /* JAY-1866 */
        settings: { 
            /* 
             * e.g. '_crawlspace' 
             * Google Analytics events will have a specific suffix, so we can track from what product they ware triggered.
             * e.g. normally the event category is `search`, so when this parameter is set, the event category may be `search_crawlspace` 
             * to indicate a Search Widget on a specific application
             */
            gaEventCategorySuffix: '',
            /* if true, will only use query parameters to prefill Search Widget */
            useQueryParams: false,
            from: undefined,
            // from: { // optional, to prefill FROM location of the Search Widget
            //   description: 'Sydney Airport (SYD), T1 International Terminal',
            //   type: 'airport-terminal', // 'hotel', 'address'
            //   lat: -33.936722,
            //   lng: 151.165831
            // },
            to: undefined
            // to: { // optional, to prefill TO location of the Search Widget
            //   description: 'Manly Beach (N Steyne, Manly NSW 2095, Australia)',
            //   type: 'address', // 'airport-terminal', 'hotel', 
            //   lat: -33.7897311,
            //   lng: 151.287784
            // }
        }
    },
    cacheHelpers: {
        /**
         * a cache helper function to set value into storage by key
         * @param {string} key storage key
         * @param {any} val storage value (can be a simple type or an object or array)
         * @param {boolean} setTimestamp if true, the value will be cached for a limited period of time
         */
        setValue: function (key, val, setTimestamp) {
            setTimestamp = setTimestamp || true;

            try { // https://github.com/auth0/angular-storage/issues/12
                const item = {
                  value: val,
                  timestamp: setTimestamp ? new Date().getTime() : null
                };
                window.localStorage.setItem(key, JSON.stringify(item));
            } catch (err) {
                throw err;
            }
        },
        /**
         * a cache helper function to get the value by storage key
         * @param {string} key storage key
         * @returns {any | undefined} value
         */
        getValue: function (key) {
            try {
                const obj = JSON.parse(window.localStorage.getItem(key));
                if (obj) {
                  if (!this.isExpired(obj.timestamp)) {
                    return obj.value;
                  } else {
                    this.removeValue(key);
                    return undefined;
                  }
                } else {
                  return undefined;
                }
            } catch (err) {
                throw err;
            }
        },
        /**
         * a cache helper function to remove value from storage by key
         * @param {string} key storage key
         */
        removeValue: function (key) {
            window.localStorage.removeItem(key);
        },
        /**
         * a cache helper function to check if the timestamp is expired
         * @param {number} timestamp 
         * @returns {boolean} true if expired
         */
        isExpired: function (timestamp) {
            if (!timestamp || !isNaN(timestamp)) {
              return false;
            }
        
            return Math.abs(new Date().getTime() - timestamp) > 86400000;
        }
    },
    /**
     * Cache Key Dictionary
     */
    cacheKeys: {SalesPhoneNumber: "jr-sales-phone-number"},
    environment: {
        apiDomain: '#{HOST_NAMES-JAYRIDE_API}#', // 'https://stage-api.jayride.com'
        apiKey: '#{API-KEY}#', // '92d3210e122648d399cb40106025b27b'
        phoneNumbersUrl: '#{HOST_NAMES-PHONE_SALES}#', // 'https://www.jayride.com/wp-content/JayridePhoneNumbers.json'
        defaultSalesPhoneNumber: '+1 646 4806835',

        auth0: {
            domain: '#{AUTH0_DOMAIN}#',
            audience: '#{AUTH0_AUDIENCE}#',
            clientId: '#{AUTH0_CLIENTID}#',
            scope: '#{AUTH0_SCOPE}#'
        }
    },
    /**
     * Get IP Address information
     * @returns {{country: string, ip: string, country_name: string, city: string}} Ip address details
     */
    getIpInfo: function () {
        try {
            let xmlHttp = new XMLHttpRequest();
            xmlHttp.open("GET", `${this.environment.apiDomain}/internal/v1/ipinfo?key=${this.environment.apiKey}`, false);
            xmlHttp.send(null);
            const response = JSON.parse(xmlHttp.responseText);

            return response;
        } catch (err) {
            throw err;
        }
    },
    /**
     * Fetches a list of sales phone numbers from a JSON file
     * @returns A list of sales phone numbers indexed by country
     */ 
    getSalesPhoneNumbers: function () {
        try {
            let xmlHttp = new XMLHttpRequest();
            xmlHttp.open("GET", this.environment.phoneNumbersUrl, false);
            xmlHttp.send(null);
            phoneNumbersList = JSON.parse(xmlHttp.responseText);

            return phoneNumbersList;
        } catch (err) {
            throw err;
        }
    },
    /**
     * Fetches the sales phone number for the user based on their location or a cached phone number, if previously called
     * @returns {string} a sales phone number
     */
    getSalesPhoneNumber: async function () {
        try {
            let salesPhoneNumber = this.cacheHelpers.getValue(this.cacheKeys.SalesPhoneNumber);

            if (!salesPhoneNumber) {
                const res = this.getIpInfo();
                const salesPhoneNumbers = this.getSalesPhoneNumbers();
                salesPhoneNumber = salesPhoneNumbers[res.country] || this.environment.defaultSalesPhoneNumber;

                this.cacheHelpers.setValue(this.cacheKeys.SalesPhoneNumber, salesPhoneNumber);
            }
            return salesPhoneNumber;
        } catch (err) {
            console.error("Error Retrieving Sales Phone Number: " + err);
            return this.environment.defaultSalesPhoneNumber;
        }
    },

    /**
     * Get agent sign in URL
     * @param {string=} callbackUrl - Redirect URL after sign in succeed
     * @returns {string}
     */
    getAgentSignInURL: function (callbackUrl) {
        if (!callbackUrl) {
            callbackUrl = window.location.href;
        }

        const signInURL = `https://${this.environment.auth0.domain}/authorize?client_id=${this.environment.auth0.clientId}&audience=${this.environment.auth0.audience}&scope=${this.environment.auth0.scope}&response_type=code&redirect_uri=${callbackUrl}&state=YWJj03VzZXJUeXBl1PWFnZW50&userType=agent`;
        return signInURL;
    },
    /**
     * Get agent sign up URL
     * @param {string=} callbackUrl - Redirect URL after sign up succeed
     * @returns {string}
     */
    getAgentSignUpURL: function(callbackUrl) {
        if (!callbackUrl) {
            callbackUrl = window.location.href;
        }

        const signUpURL = `https://${this.environment.auth0.domain}/authorize?screen_hint=signup&client_id=${this.environment.auth0.clientId}&audience=${this.environment.auth0.audience}&scope=${this.environment.auth0.scope}&response_type=code&redirect_uri=${callbackUrl}&state=YWJj03VzZXJUeXBl1PWFnZW50&userType=agent`;
        return signUpURL;
    },

    /** Jayride Multilingual */
    multilingual: {
        /**
         * @readonly
         * @enum {string}
         */
        langs: {
            en: 'en',
            es: 'es'
        },
        currentLang: undefined,
        /** @readonly */
        _activeItemCSSlassName: 'active',

        /**
         * @param {string} lang - a new language to ser up as a preferred language
         * @param {boolean=true} updateUrl - update rurrent url with the language parameter. E.g. jayride.com/how-it-works => jayride.com/es/how-it-works
         * @param {boolean=true} refreshPage - refresh page after switching the language
         */
        selectLanguage: function(lang, updateUrl = true, refreshPage = true) {
            this.currentLang = lang;
    
            this._updateDomWithSelectedLang(this.currentLang);
            this._updateAllLinksOnPage(lang);
            
            if (updateUrl) {
                this._updateUrlWithSelectedLang(this.currentLang);
            }
            if (refreshPage) {
                window.location.reload();
            }
        },
        /**
         * @param {string} lang
         */
        _updateDomWithSelectedLang: function(lang) {
            try {
                // set labels
                var lbls = document.getElementsByName('jr-ml-current-lang-lbl');
                if (lbls) {
                    lbls.forEach(lbl => {
                        lbl.innerHTML = this.getLangLabel(lang);
                    });
                }
            } catch (err) {
                console.error(err);
            }

            try {
                let desktopDropdownEl = document.getElementById('jr-ml-dropdown');
                // unselect active items
                desktopDropdownEl.querySelectorAll('.dropdown-item').forEach(item => {
                    item.classList.remove(this._activeItemCSSlassName);
                });
                // set active list items
                desktopDropdownEl.querySelector(`[name="${lang}"]`).classList.add(this._activeItemCSSlassName);
            } catch (err) {
                console.error(err);
            }

            try {
                let modalDropdownEl = document.getElementById('jr-ml-dropdown-modal');
                // unselect active items
                modalDropdownEl.querySelectorAll('.list-group-item').forEach(item => {
                    item.classList.remove(this._activeItemCSSlassName);
                });
                // set active list items
                modalDropdownEl.querySelector(`[name="${lang}"]`).classList.add(this._activeItemCSSlassName);
            } catch (err) {
                console.error(err);
            }
        },
        /**
         * @param {string} lang
         */
        _updateUrlWithSelectedLang: function(lang) {
            var urlChunks = window.location.pathname.split('/');
            urlChunks[1] = lang;
            window.history.replaceState(undefined, document.title, urlChunks.join('/'));
        },
        /**
         * @param {string} lang e.g. 'en' | 'es'
         */
        _updateAllLinksOnPage: function(lang) {
            let aEls = document.querySelectorAll('a');
            aEls.forEach(aEl => {
                if (aEl.href) {
                    aEl.href = this._replaceLangInUrl(aEl.href, lang);
                }
            });
        },
        /**
         * @param {string} url
         * @param {string} lang
         * @returns {string}
         */
        _replaceLangInUrl: function(url, lang) {
            if(!url || !lang) { return; }
    
            try {
                const urlChunks = url.split('/');
                // check if correct domain
                const isDevEnv = url.includes('localhost');
                const isRelativeUrl = url.startsWith('/');
                const isJayrideDomain = urlChunks[2] ? urlChunks[2].includes('jayride.com') : false;
    
                if (isDevEnv || isRelativeUrl || isJayrideDomain) {
                    // check if has lang param
                    // TODO: check if supported lang param
                    if (isRelativeUrl) {
                        // replace lang param
                        urlChunks[1] = lang;
                    } else {
                        urlChunks[3] = lang;
                    }
                    return urlChunks.join('/');
                }
            } catch(err) {
                console.error(err);
            }
            return url;
        },
        /**
         * @returns {string} e.g. 'en'
         */
        getCurrentLanguageFromUrl: function() {
            var urlChunks = window.location.pathname.split('/');
            switch (urlChunks[1]) {
                case 'en': return this.langs.en;
                case 'es': return this.langs.es;
                default: return undefined;
            }
        },
        /**
         * @param {string} lang e.g. 'en' | 'es'
         * @returns {string} e.g. 'English'
         */
        getLangLabel: function(lang) {
            switch (lang) {
                case this.langs.en: return 'English';
                case this.langs.es: return 'Español';
                default: return 'English';
            }
        },
        /** ENTRY POINT; call this function on page load */
        initLang: function() {
            let lng = this.getCurrentLanguageFromUrl();
            this.selectLanguage(lng, false, false);
        }
    }
};
